<template>
  <div>
    <van-nav-bar left-arrow title="项目快讯" fixed @click-left="$router.push({ name: 'Home' })" />

    <div class="content">
      <van-search
        placeholder="请输入快讯主题"
        @search="onSearch"
        v-model="value"
        shape="round"
        show-action
        @cancel="onCancel"
      />

      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <van-panel
          :title="item.documentTitle"
          :desc="item.documentDesc"
          @click="choseList(item)"
          v-for="(item, index) in list"
          :key="index"
        >
        </van-panel>
      </van-list>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      pageNum: 0,
      pageSize: 10,
      value: '',
      where: []
    };
  },

  methods: {
    onLoad() {
      this.$myHttp({
        method: 'post',
        url: '/microarch/sys/SysDocument/findNameByOrgCode',
        data: {
          where: this.where,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          orderBy: 'createTime desc',
          entity: {
            documentType: '04'
          }
        }
      }).then(res => {
        this.list = this.list.concat(res.data.rows);
        this.pageNum++;
        this.pageSize = this.pageSize;
        if (res.data.total <= this.pageSize) this.finished = true;
      });
    },
    onSearch() {
      this.list = [];
      this.where = [{ field: 'documentTitle', opt: 'like', value: this.value, assemble: 'and' }];
      this.onLoad();
    },
    onCancel() {
      this.value = '';
      this.where = [{ field: 'documentTitle', opt: 'like', value: '', assemble: 'and' }];
      this.list = [];
      this.onLoad();
    },
    choseList(item) {
      this.$router.push({
        name: 'messageView',
        query: {
          item: item,
          type: 4
        }
      });
    }
  },
  created() {},
  filters: {}
};
</script>

<style scoped>
.info {
  padding-left: 15px;
  padding-bottom: 10px;
  font-size: 13px;
  color: #454546;
}

.content {
  margin-top: 40px;
}
.van-panel__header-value {
  color: green;
}

.van-cell:not(:last-child)::after {
  border-bottom: none;
}

.van-cell__title {
  color: #333;
  line-height: 1.3;
  margin: 0 0 5px 0;
}
</style>
