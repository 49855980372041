<template>
  <div class="commentArea">
    <van-cell-group>
      <van-field v-model="commentText" placeholder="添加评论或点赞" />
    </van-cell-group>
    <div class="actionBtn">
      <span>{{ thumbsNum }}人已赞</span>
      <div class="commentBtn">
        <van-button type="primary" size="mini" icon="good-job-o" @click="giveGood">点赞</van-button>
        <van-button type="info" size="mini" icon="chat-o" @click="giveComment">评论</van-button>
      </div>
    </div>
    <div class="thumbsNum">
      <span>{{ thumbsNames }}</span>
    </div>
    <div class="messageBox">
      <div class="everyMessage" v-for="(item, index) in allComments" :key="index">
        <h3 class="mesTit">
          <span class="blueLine"></span>
          <span class="mesText">
            <strong>{{ item.cname }}：</strong>
            {{ item.content }}
          </span>
        </h3>
        <p class="clickFn">
          <span class="replyTime">{{ item.lastmodifiedTime }}</span>
          <span class="canClick" :ref="item.replyBtn" @click="replyMessage(index)">回复</span>
          <span class="canClick" v-if="item.cname == loginName" @click="delComment(item.id, index)">删除</span>
        </p>
        <div class="reply" v-if="item.replyFlag">
          <van-cell-group>
            <van-field v-model="item.replyText" placeholder="回复评论" style="margin:10px 0;" />
          </van-cell-group>
          <div class="replyBtn">
            <van-button size="small" style="margin-right: 5px" @click="cancelReply(index)">取消</van-button>
            <van-button size="small" type="primary" @click="toComment(item.id, item.fromUid, index)">回复</van-button>
          </div>
        </div>
        <div class="replyList">
          <ul>
            <li
              v-for="(replyItem, index) in item.replies && item.replies.length >= 3
                ? item.replies
                    .slice(0, item.replies.length)
                    .reverse()
                    .slice(0, 3)
                : item.replies && item.replies.length > 0 && item.replies.length < 3
                ? item.replies.slice(0, item.replies.length).reverse()
                : item.replies"
              :key="index"
            >
              <h3 class="mesTit">
                <span class="mesText">
                  <strong>{{ replyItem.cname }}：</strong>
                  {{ replyItem.content }}
                </span>
              </h3>
              <p class="clickFn">
                <span class="replyTime">{{ replyItem.lastmodifiedTime }}</span>
                <span
                  class="canClick"
                  v-if="replyItem.cname == loginName"
                  @click="delReplyItem(replyItem.id, item.id, index)"
                  >删除</span
                >
              </p>
            </li>
            <li
              v-if="!item.showReplyFlag"
              :key="index"
              v-for="(replyItem, index) in item.replies && item.replies.length > 3
                ? item.replies
                    .slice(0, item.replies.length)
                    .reverse()
                    .slice(3, item.replies.length)
                : []"
            >
              <h3 class="mesTit">
                <span class="mesText">
                  <strong>{{ replyItem.cname }}：</strong>
                  {{ replyItem.content }}
                </span>
              </h3>
              <p class="clickFn">
                <span class="replyTime">{{ replyItem.lastmodifiedTime }}</span>
                <span class="canClick" v-if="replyItem.cname == loginName">删除</span>
              </p>
            </li>
          </ul>
          <span
            class="moreReply"
            v-if="item.replies && item.replies.length > 3 && item.showReplyFlag"
            @click="getAllReply($event, item.id, index)"
            >共{{ item.replies.length }}条评论</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      commentText: '',
      replyText: '',
      replyBtnDom: '',
      thumbsNum: 0,
      thumbsNames: '',
      isGiveGoodOrNotFlag: false, //判断是否已点赞
      allComments: [], //所有评论列表
      loginName: sessionStorage.getItem('cname'),
      replyNum: []
    };
  },
  methods: {
    delComment(commentId, index) {
      this.$myHttp({
        method: 'post',
        url: '/microarch/sys/comment/delete',
        headers: {
          isLoading: false
        },
        data: {
          entity: {
            id: commentId
          }
        }
      }).then(res => {
        this.getAllComments(); //刷新评论列表
      });
    },
    delReplyItem(replyId, commentId, index) {
      this.$myHttp({
        method: 'post',
        url: '/microarch/sys/comment/delSysReplay',
        data: {
          entity: {
            id: replyId
          }
        }
      }).then(res => {
        this.getAllReply(false, commentId, index); //刷新回复列表
      });
    },
    getAllReply(event, commentId, index) {
      // console.log('event--',event)
      this.$myHttp({
        method: 'post',
        url: '/microarch/sys/comment/findReply',
        data: {
          entity: {
            commentId: commentId
          }
        }
      }).then(res => {
        // console.log('allReply---',res);
        // console.log('index---',index);
        if (res.data) {
          let repliesValue = res.data.rows;
          this.$set(this.allComments[index], 'replies', repliesValue);
          if (event && event.target.className == 'moreReply') {
            this.$set(this.allComments[index], 'showReplyFlag', false);
          }
        }
        // console.log('getAllReply---',this.allComments[index]);
      });
    },
    giveComment() {
      let commentText = this.commentText.replace(/^(\s)*|(\s)*$/g, '');
      if (commentText == '') {
        this.$toast.fail('评论内容不能为空');
        return;
      }
      if (commentText.length > 500) {
        this.$toast.fail('评论内容不能超过500个字符');
        return;
      }
      this.$myHttp({
        method: 'post',
        url: '/microarch/sys/comment/saveComment',
        data: {
          entity: {
            commentType: 'content',
            topicId: this.$route.query.item.id,
            content: commentText
          }
        }
      }).then(res => {
        console.log('res---comment---', res);
        if (res.data.entity) {
          this.commentText = '';
          this.getAllComments(); //刷新评论列表
        }
      });
    },
    toComment(commentId, fromUid, index) {
      let content = this.allComments[index].replyText;
      content = content.replace(/^(\s)*|(\s)*$/g, '');
      if (content == '') {
        this.$toast.fail('回复内容不能为空');
        return;
      }
      if (content.length > 500) {
        this.$toast.fail('回复内容不能超过500个字符');
        return;
      }
      this.$myHttp({
        method: 'post',
        url: '/microarch/sys/comment/replay',
        data: {
          entity: {
            commentId: commentId,
            toUid: fromUid,
            content: content
          }
        }
      }).then(res => {
        console.log('contentsave---res---', res);
        this.getAllReply(false, commentId, index); //刷新回复内容
        this.cancelReply(index); //隐藏回复框
        this.allComments[index]['replyText'] = '';
      });
    },
    replyMessage(index) {
      // console.log('replyBtn----',this.$refs['replyBtn'+index])
      this.$refs['replyBtn' + index][0].className = 'canClick active';
      this.$set(this.allComments[index], 'replyFlag', true);
      let tempComments = this.allComments;
      this.allComments = [];
      this.allComments = tempComments;
    },
    cancelReply(index) {
      this.$set(this.allComments[index], 'replyFlag', false);
      this.$refs['replyBtn' + index][0].className = 'canClick';
      let tempComments = this.allComments;
      this.allComments = [];
      this.allComments = tempComments;
    },
    getThumbsNum() {
      this.$myHttp({
        method: 'post',
        url: '/microarch/sys/comment/findAllUser',
        data: {
          entity: {
            topicId: this.$route.query.item.id
          }
        }
      }).then(res => {
        console.log('thumbsnum---', res);
        this.thumbsNum = res.data.rows.length;
        this.thumbsNames = '';
        for (var i = 0; i < res.data.rows.length; i++) {
          this.thumbsNames += res.data.rows[i].cname + '、';
        }
      });
    },
    isGiveGoodOrNot() {
      this.$myHttp({
        method: 'post',
        url: '/microarch/sys/comment/giveGoodExist',
        data: {
          entity: {
            topicId: this.$route.query.item.id
          }
        }
      }).then(res => {
        this.isGiveGoodOrNotFlag = res.data.ext;
      });
    },
    giveGood() {
      if (this.isGiveGoodOrNotFlag) return;
      this.$myHttp({
        method: 'post',
        url: '/microarch/sys/comment/saveComment',
        data: {
          entity: {
            commentType: 'img',
            topicId: this.$route.query.item.id
          }
        }
      }).then(res => {
        this.isGiveGoodOrNotFlag = true;
        this.getThumbsNum(); //刷新点赞人数及人名
      });
    },
    getAllComments() {
      this.$myHttp({
        method: 'post',
        url: '/microarch/sys/comment/findAllReply',
        data: {
          entity: {
            topicId: this.$route.query.item.id
          }
        }
      }).then(res => {
        console.log('allComments---', res);
        this.allComments = res.data.rows;
        this.allComments = this.allComments.reverse();
        for (var i = 0; i < this.allComments.length; i++) {
          this.allComments[i].replyFlag = false;
          this.allComments[i]['replyBtn'] = 'replyBtn' + i;
          this.allComments[i]['replyText'] = '';
          this.getAllReply(false, this.allComments[i].id, i); //查找所有评论下的回复
          this.allComments[i]['showReplyFlag'] = true;
        }
      });
    }
  },
  mounted() {
    this.getThumbsNum(); //获取所有点赞用户
    this.isGiveGoodOrNot(); //确定是否已点赞
    this.getAllComments(); //获取该文章的所有评论
  }
};
</script>
<style scoped>
.commentArea {
}
.actionBtn {
  height: 31px;
  line-height: 31px;
  padding: 10px 0;
  clear: both;
  font-size: 13px;
}
.commentBtn {
  float: right;
}
.thumbsNum {
  padding: 10px 0;
  padding-left: 20px;
  padding-bottom: 15px;
  position: relative;
  border-bottom: 1px solid #eee;
  color: #2d8cf0;
  clear: both;
  font-size: 12px;
}
.thumbsNum span {
  white-space: normal;
  word-break: break-all;
  line-height: 20px;
}
.thumb {
  position: absolute;
  left: 0;
  top: 10px;
}
.messageBox {
  margin: 20px 0;
  font-size: 12px;
}
.messageBox .everyMessage {
  position: relative;
  margin-bottom: 20px;
}
.everyMessage h3.mesTit {
  position: relative;
  padding-left: 15px;
  margin: 5px 0;
  font-weight: normal;
}
.everyMessage h3.mesTit .blueLine {
  position: absolute;
  left: 0;
  top: 5px;
  border-left: 3px solid #2d8cf0;
  height: 12px;
}
.everyMessage h3.mesTit .mesText {
  line-height: 20px;
  font-size: 14px;
  color: #666;
  white-space: normal;
  word-break: break-all;
}
.everyMessage h3.mesTit strong {
  color: #999;
}
.everyMessage .clickFn {
  color: #666;
  padding-left: 15px;
}
.clickFn span {
  margin-right: 20px;
}
.clickFn span.replyTime {
  color: #999;
}
.clickFn span.canClick {
  cursor: pointer;
}
.clickFn span.canClick:hover,
.clickFn span.canClick.active {
  color: #2d8cf0;
}
.reply {
  width: 60%;
  margin-left: 15px;
}
.replyBtn {
  text-align: right;
}
.replyList {
  margin-left: 15px;
  background: #ecf0f9;
  margin-top: 10px;
  overflow: hidden;
}
.everyMessage .replyList h3.mesTit .mesText {
  font-size: 12px;
}
.everyMessage .replyList h3.mesTit .mesText strong {
  font-weight: normal;
}
.replyList li {
  margin-bottom: 15px;
}
.replyList li .mesText {
  font-size: 12px;
  color: #333;
}
.replyList li .mesText strong {
  color: #2d8cf0;
}
.replyList .moreReply {
  color: #2d8cf0;
  cursor: pointer;
  padding: 0 15px 10px;
  display: inline-block;
}
</style>
