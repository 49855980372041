<template>
  <div>
    <van-nav-bar left-arrow title="消息通知" fixed @click-left="$router.push({ name: 'Home' })" />

    <div class="content">
      <van-search
        placeholder="请输入搜索主题"
        @search="onSearch"
        v-model="value"
        shape="round"
        show-action
        @cancel="onCancel"
      />

      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" :offset="50" @load="onLoad">
        <van-panel @click="choseList(item)" v-for="(item, index) in list" :key="index">
          <div slot="header" :class="item.status === '0' ? 'unread' : 'read'">
            <div style="font-size: 14px;">
              {{ item.subject }}
            </div>

            <div style="font-size: 11px;margin-top: 5px;margin-bottom: 5px">
              {{ item.content }}
            </div>
            <div style="font-size: 12px;color: #969799">
              {{ item.createTime }}
            </div>
          </div>
        </van-panel>
      </van-list>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      pageNum: 0,
      pageSize: 10,
      value: '',
      where: [
        { field: 'type', opt: '=', value: 'MSG', assemble: 'and' },
        { field: 'emailTo', opt: '=', value: sessionStorage.getItem('userName'), assemble: 'and' }
      ]
    };
  },

  methods: {
    onLoad() {
      this.$myHttp({
        method: 'post',
        url: '/microarch/sys/message/list',
        data: {
          where: this.where,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          orderBy: 'createTime desc'
        }
      }).then(res => {
        this.list = this.list.concat(res.data.rows);
        this.pageNum++;
        this.loading = false;
        if (this.list.length >= res.data.total) this.finished = true;
      });
    },
    onSearch() {
      this.where = [{ field: 'subject', opt: 'like', value: this.value, assemble: 'and' }];
      this.list = [];
      this.onLoad();
    },
    onCancel() {
      this.value = '';
      this.pageNum = 0;
      this.pageSize = 10;
      this.where = [
        { field: 'type', opt: '=', value: 'MSG', assemble: 'and' },
        { field: 'emailTo', opt: '=', value: sessionStorage.getItem('userName'), assemble: 'and' }
      ];
      this.list = [];
      this.onLoad();
    },
    choseList(item) {
      this.$myHttp({
        method: 'POST',
        url: '/microarch/sys/message/read?ids=' + [item.id]
      }).then(res => {
        this.onCancel();
        this.$emit('getMessage');
      });
      /*
      this.$router.push({
        name: 'todoDetail',
        query: item
      });
      */
    }
  },
  created() {},
  filters: {}
};
</script>

<style lang="less" scoped>
.read {
  padding: 10px 0 5px 20px;
  color: #969799;
}
.unread {
  padding: 10px 0 5px 20px;
  color: #333;
}
.content {
  margin-top: 40px;
}
.van-panel__header-value {
  color: green;
}

.van-cell:not(:last-child)::after {
  border-bottom: none;
}

.van-cell__title {
  color: #333;
  line-height: 1.3;
  margin: 0 0 5px 0;
}
</style>
