<template>
  <div style="padding:10px;background: #fff;">
    <van-nav-bar left-arrow title="信息查看" fixed @click-left="$router.go(-1)" />

    <fieldset style="margin-bottom: 40px;margin-top: 50px">
      <legend>{{ title }}</legend>
      <div class="newsCon" v-html="news">{{ news }}</div>
      <comment></comment>
    </fieldset>
  </div>
</template>

<script>
import comment from './content';

export default {
  name: 'newsView',
  data() {
    return {
      item: {},
      id: '',
      news: '',
      viewType: '',
      type: '',
      title: '',
      commentText: ''
    };
  },
  components: { comment },
  created() {
    this.item = this.$route.query.item;
    this.type = this.$route.query.type;

    if (this.type === 1) {
      this.title = '新闻公告-内容';
    }
    if (this.type === 4) {
      this.title = '项目快讯-内容';
    }
    if (this.type === 5) {
      this.title = '轮播图-内容';
    }
    this.backfilleditData();
  },
  methods: {
    backfilleditData() {
      this.$myHttp({
        method: 'post',
        url: '/microarch/sys/SysDocument/viewNews',
        data: {
          entity: {
            id: this.item.id
          }
        }
      }).then(res => {
        this.news = res.data;
      });
    },
    backList() {
      if (this.viewType == 'home') {
        this.$router.push({
          name: 'home_index'
        });
      } else {
        this.$router.push({
          name: 'newsList',
          query: {
            type: this.type
          }
        });
      }
    }
  }
};
</script>

<style>
fieldset {
  padding: 10px;
  color: #333;
  border: #06c solid 1px;
}
legend {
  color: #06c;
  font-weight: 800;
  background: #fff;
  border: #b6b6b6 solid 1px;
  padding: 3px 6px;
  font-size: 14px;
}
.newsCon {
  padding-bottom: 30px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
}
</style>
